import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthProvider';
import { Auth0Provider } from '@auth0/auth0-react';
// import AudienceList from './components/AudienceList';
// import SegmentList from './components/SegmentList';
// import QuestionsList from './components/QuestionsList';
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

Sentry.init({
  dsn: "https://1e0f91f8f88434c8d586e57012feb4f5@o4507748708581376.ingest.us.sentry.io/4507748712579072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}

    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:current_user read:current_user_metadata"
    }}
    cacheLocation="localstorage"
  >
    <AuthProvider>
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </AuthProvider>
  </Auth0Provider >
);
reportWebVitals();
