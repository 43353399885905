import React, { useState } from 'react';
import Button from '@mui/material/Button';
import close from '../../assets/close.svg';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import upload from '../../assets/upload-icon.svg';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import templateDoc from '../../assets/documents/Crowdwave Survey Questions Upload Template.xlsx';
import { styled } from '@mui/material/styles';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DynamicCardList from '../dynamic/DynamicCardList';

const Questions = ({
  activeStep,
  questionsData,
  setQuestionsData,
  hasQuestionsData,
  handleUpload,
  newQuestionText,
  setNewQuestionText,
  hasQuestionTextError,
  handleRemoveValidationForQuestionsChange,
  newQuestionType,
  handleQuestionTypeFieldChange,
  newQuestionOptions,
  setNewQuestionOptions,
  hasQuestionOptionsError,
  addQuestion,
  addQuestionsLimit,
  validateQuestion,
  enableOptionsEditing,
  hasQuestionCountError,
  audienceData,
  hasAudienceData,
  segmentData,
  hasSegmentData,
  questionActions,
  questionDeleteCallback,
  questionsWithErrors,
  currentContext,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const uploadInfoRows = [
    { question_text: 'Do you enjoy cooking?', question_type: 'Short Response', sort_order: 1, options: '' },
    { question_text: 'How would you describe your diet?', question_type: 'Single Choice', sort_order: 4, options: 'Vegan,Dietary Restrictions,Allergen Restrictions,No Restrictions' },
    { question_text: 'What types of food do you buy?', question_type: 'Multiple Choice', sort_order: 2, options: 'Fresh,Frozen,Organic,Local,Imported' },
    { question_text: 'Rate your diet from 1 (unhealthy) to 5 (very healthy)', question_type: 'Numeric Rating', sort_order: 3, options: '' },
  ];

  const emptyQuestion = {
    id: 0,
    survey_id: '',
    question_id: '',
    question_text: '',
    question_type: 'short response',
    options: [],
    raw_options: '',
    sort_order: 1,
    has_text_error: false,
    has_options_error: false,
    has_count_error: false
  };

  return (
  <Grid container>
    <Grid xs={12} md={12} lg={12} >
      <Container className="left-panel stacked-top">
        <h2>Questions</h2>
        <p>Add your questions here.</p>
        <div>
          <Grid container spacing={2}>
            <Grid xs={12} md={12} lg={5} style={{ display: isActive ? 'none' : 'block' }}>
              <Button
                style={{ top: '30%' }}
                onClick={handleToggle}
                variant="contained"
                startIcon={<CloudUploadIcon />}
                xs={12} md={12} lg={4}
                className="crowdwave-blue-light">
                Bulk Upload Questions
              </Button>
            </Grid>
            <Grid style={{ display: isActive ? 'block' : 'none' }} xs={6} md={6} lg={7}>
              <p>
                Upload your own document to the right, or download our <a href={templateDoc}>pre-built XLSX template</a> to ensure compatibility.
                The required format is shown below:
              </p>
            </Grid>
            <Grid className='upload-block' style={{ display: isActive ? 'flex' : 'none' }} xs={12} md={12} lg={5}>
              <em className='close-icon' onClick={handleToggle}> <img src={close} alt="upload" /></em>
              <Button component="label" role={undefined} variant="contained" data-test-id="upload-questions-btn">
                <em><img src={upload} alt="upload" /></em> Click to Upload XLSX
                <VisuallyHiddenInput type="file" accept=".xlsx" onChange={(e) => handleUpload(e)} />
              </Button>
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <TableContainer className="upload-info-text" component={Paper} style={{ display: isActive ? 'block' : 'none' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: '2px solid #999' }}>
                      <TableCell>Question Text</TableCell>
                      <TableCell align="center">Question Type</TableCell>
                      <TableCell align="center">Sort Order</TableCell>
                      <TableCell align="center">Options</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadInfoRows.map((row) => (
                      <TableRow
                        key={row.sort_order}
                      >
                        <TableCell>{row.question_text}</TableCell>
                        <TableCell align="center">{row.question_type}</TableCell>
                        <TableCell align="center">{row.sort_order}</TableCell>
                        <TableCell align="center">{row.options}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
          <DynamicCardList
            label={'question'}
            listData={questionsData}
            setListData={setQuestionsData}
            emptyItem={emptyQuestion}
            itemDeleteCallback={questionDeleteCallback}
            itemsWithErrors={questionsWithErrors}
            isSortable={true}/>
          </Grid>
        </div>
      </Container>
    </Grid>
  </Grid>
)}

export default Questions;